.card.p-card {
    background-color: var(--app-background, #fff) !important;
    width: fit-content !important;
    height: max-content !important;
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
    box-shadow: none !important;
    color: var(--app-text-color, #fff) !important;
}

.card.p-card .p-card-footer .p-button{
    margin-left: 34% !important;
    color: red;
}

.h1MsgFont {
    font: 40px arial sans-serif;
}

.h2MsgFont {
    font: 35px arial sans-serif;
}

.centerText {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

