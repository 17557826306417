.right-label {
  display: flex;
  align-items: center;
  flex-basis: unset !important;
  margin: 0 1.25% 0 0 !important;
}

.right-label .content-input {
  margin: 0 !important;
}

.right-label label {
  font-size: 12px !important;
}

#loaderModal {
  z-index: 9998;
  display: none;
}

#loaderModal.show {
  display: flex;
}

/* ====================================
//  NOTIFICATIONS
// ================================= */
.Toastify__toast-container .Toastify__toast {
  border-radius: 5px;
  padding: 20px;
}

.Toastify__toast-container .Toastify__toast .Toastify__close-button {
  position: absolute;
  top: 8px;
  right: 8px;
}

.Toastify__toast-container .Toastify__toast .Toastify__toast-body {
  overflow-wrap: anywhere;
  line-height: 21px;
}

/* ====================================
//  RESPONSIVE
// ================================= */
@media (max-width: 780px) {
  ::local() .p-dialog .p-dialog-content{
    padding: 0;
  }
}