/* put your custom-base css here */ 
link-text { 
  font-size: 12px; 
  font-weight: normal; 
  display: block; 
  width: 150px; 
  float: left; 
  padding-top: 10px; 
  padding-left: 7px; 
} 
  
.content-input-link-text link-text { 
  font-family: var(--app-font-family, "Open Sans"), "Helvetica Neue", sans-serif; 
  font-family: 'Roboto', sans-serif !important; 
}