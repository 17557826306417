.header-content {
  height: 64px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: visible;
  padding: 0 45px;
  z-index: 999;
  background-color: var(--app-header, #fff);
  border-bottom: 1px solid var(--app-header-border, #ccc);
}

.header-content {
  display: flex;
  justify-content: space-between;
  height: 64px;
}

#logo {
  display: inline-block;
}

#logo img {
  max-height: 36px;
}

div.header-wrapper {
  height: 48px;
  display: flex;
  flex: 1;
  flex-direction: row;
}

div.left,
div.right {
  display: flex;
  align-items: center;
}

div.right button {
  opacity: 0.9;
  color: var(--app-text-color, #222) !important;
  background: transparent;
  border: none;
  font-size: 18px;
  border-radius: 50%;
  outline: none !important;
  box-shadow: none !important;
}

div.right button:hover {
  background: rgba(0, 0, 0, 0.2) !important;
}

div.right button:focus {
  background: transparent !important;
}

div.right button:active {
  background: rgba(0, 0, 0, 0.3) !important;
}

#langSwitch {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  border-radius: 4px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin-right: 4px;
  padding: 6px 12px;
  background-color: var(--main-color-accent, #007ad9);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}

#langSwitch:hover {
  background-color: var(--main-color-dark, #005597);
}

/* ====================================
//  RESPONSIVE
// ================================= */
@media (max-width: 780px) {
  :local() {
    padding: 0 18px;
  }
}