.p-accordion ul {
  padding: 0;
  margin: 0;
}

.p-accordion ul li {
  margin: 8px 0;
}

.p-accordion ul li button {
  color: var(--app-text-color, #fff) !important;
  background: none;
  border: none;
  box-shadow: none;
  font-size: 14px;
  padding: 8px;
  width: 100%;
  text-align: left;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.p-accordion ul li button:hover {
  background-color: var(--nav-background-item-hover, #dcdcdc) !important;
}

.p-accordion .p-accordion-toggle-icon, 
.p-accordion .p-accordion-header-text,
.p-accordion .p-accordion-header a .p-accordion-toggle-icon {
  color: var(--app-text-color, #fff) !important;
}

.p-accordion .p-accordion-header a {
  background-color: var(--button-fkey, #f4f4f4) !important;
  border-color: var(--button-fkey, #f4f4f4) !important;
}

.p-accordion .p-accordion-header a:hover,
.p-accordion .p-accordion-header a:focus,
.p-accordion .p-accordion-header a:active {
  background-color: var(--button-fkey-hover, #c8c8c8) !important;
  border-color: var(--button-fkey-hover, #c8c8c8) !important;
}

.p-accordion .p-accordion-content {
  background-color: var(--app-background, #fff) !important;
  border: none !important;
}

.menu-error p {
  margin: 0;
  color: var(--app-error-color, #ec0707) !important;
  border: 1px solid var(--app-error-color, #ec0707);
  border-radius: 2px;
  font-size: 14px;
  padding: 8px;
}